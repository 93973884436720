import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import Pager from "../components/Pager"
import { Row}  from 'react-bootstrap'
import * as styles from '../styles/list-item.module.css'

import ArticleMiniPreview from '../components/ArticleMiniPreview'
const PHP_Snippets_List = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <Seo
                title="PHP How To"
                description="List of PHP How To"
            />     
            <h1>PHP/LARAVEL HOW TO</h1>              
            <Row className={styles.list}>
                {examples.map(item => (
               <ArticleMiniPreview key={item.id}
                   title={item.frontmatter.title}
                   link = {"/" + item.frontmatter.slug}
                />           
            ))}
            </Row>
            <Pager pageContext={pageContext} />
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/howto/php/*"}}
        sort: {order: ASC, fields: frontmatter___title}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default PHP_Snippets_List;